import React, { useEffect } from "react";
import Logo from "../assets/primary.svg";
import handshake from "../assets/handshake_1f91d.png";
import rocket from "../assets/rocket_1f680.png";

const Thanks = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "https://calendly.com/estatekit-strategy/session";
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="funnel !overflow-visible">
        <nav className="w-full h-16 shadow-sm !bg-white flex items-center justify-center gap-5">
          <img src={Logo} width={140} alt="Company Logo" />
        </nav>
        <div className="flex items-center justify-center gap-2 mt-20">
          <img src={handshake} className="w-16 md:w-28" alt="" />
          <img src={rocket} className="w-16 md:w-28" alt="" />
        </div>
        <h2 className="md:mx-auto md:w-6/12 !text-5xl md:!text-8xl gap-3 my-5">
          Thank you for submitting the form!{" "}
        </h2>
        <p className="text-2xl md:text-3xl font-medium md:w-3/6 md:mx-auto !text-center">
          You will be redirected to the booking page shortly!
        </p>
      </div>
    </>
  );
};

export default Thanks;
