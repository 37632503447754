import Telephone from "../assets/rocket_1f680.png";
import Chart from "../assets/confetti-ball_1f38a.png";
import CheckMark from "../assets/check-mark.png";

const Intro = () => {
  return (
    <>
      <div className="funnel !overflow-visible">
        <div className="flex items-center justify-center gap-2">
          <img src={Telephone} className="w-16 md:w-28" alt="" />
          <img src={Chart} className="w-16 md:w-28" alt="" />
        </div>
        <h2 className="md:mx-auto md:w-6/12 !text-5xl md:!text-8xl gap-3 my-5">
          Let’s Make Your Next Campaign a Success!
        </h2>
        <p className="text-2xl text-left md:text-center md:text-3xl font-medium md:w-3/6 md:mx-auto">
          Apply for your <span className="font-bold">FREE</span> no-obligation,
          no bs call <span className="text-main font-bold">worth R$912</span>.
          Strictly for serious people genuinely looking to scale their real
          estate businesses and escape uncertainty stress.
        </p>
        <ul className="text-lg md:text-xl font-medium gap-2 mx-auto my-6 leading-none flex lg:w-5/12 flex-wrap text-center items-center justify-center">
          <li className="rounded-xl bg-white shadow w-full lg:w-fit p-2 px-3 flex items-center gap-2.5 text-left">
            <img src={CheckMark} className="w-5" alt="" />
            Winning ad strategy that fits your market
          </li>
          <li className="rounded-xl bg-white shadow w-full lg:w-fit p-2 px-3 flex items-center gap-2.5 text-left">
            <img src={CheckMark} className="w-5" alt="" />
            High-conversion lead funnel
          </li>
          <li className="rounded-xl bg-white shadow w-full lg:w-fit p-2 px-3 flex items-center gap-2.5 text-left">
            <img src={CheckMark} className="w-5" alt="" />
            Ad secrets no one in SA is using *
          </li>
          <li className="rounded-xl bg-white shadow w-full lg:w-fit p-2 px-3 flex items-center gap-2.5 text-left">
            <img src={CheckMark} className="w-5" alt="" />
            Automated leads to clients system
          </li>
        </ul>
      </div>
    </>
  );
};

export default Intro;
